import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap, first, switchMap, map, take } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { Store, select } from '@ngrx/store';


@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = new Subject<boolean>();

  constructor(
    
  ) {}
  
  show() {
    this.isLoading.next(true);
  }
  hide() {
      this.isLoading.next(false);
  }
}