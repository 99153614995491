import { NgModule } from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';
import { LoginComponent } from '../onBoarding/login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OnBoardingGuard } from './on-boarding.guard';
import { AutoLoginComponent } from './autologin/autologin.component';


const appRoutes: Routes = [
  {
    path: 'auth',
    canActivateChild: [OnBoardingGuard],
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'login/:token', component: AutoLoginComponent },
    ]
  },  
  {
    path: 'login',
    // canActivateChild: [OnBoardingGuard],
    children: [
      { path: ':token', component: AutoLoginComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(
      appRoutes
    )
  ],
  exports: [
    RouterModule,
  ]
})
export class OnBoardingRoutingModule {}
