import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { Commande } from "src/app/models/commande";
import { CommandesService } from "../commandes/commandes.service";
import { Observable } from "rxjs";

@Pipe({ name: "moment" })
export class MomentPipe implements PipeTransform {
  transform(date: number | string, format: string): string {
    return moment(date).format(format);
  }
}

@Pipe({ name: "hour" })
export class MomentHourPipe implements PipeTransform {
  transform(date: number | string): string {
    return date ? moment(date).format("HH:mm") : "";
  }
}
@Pipe({ name: "day" })
export class MomentDayPipe implements PipeTransform {
  transform(date: number | string): string {
    return date ? moment(date).format("dddd Do MMMM YYYY") : "";
  }
}

@Pipe({ name: "week" })
export class MomentWeekPipe implements PipeTransform {
  transform(date: number | string): string {
    let weekNumber = moment(date).week();
    let firstDayOfWeek = moment(date).startOf("week").format("D");
    let lastDayOfWeek = moment(date).endOf("week").format("Do MMMM YYYY");
    return `Semaine ${weekNumber} - ${firstDayOfWeek} au ${lastDayOfWeek}`;
  }
}

@Pipe({ name: "month" })
export class MomentMonthPipe implements PipeTransform {
  transform(date: number | string): string {
    return moment(date).format("MMMM YYYY");
  }
}

@Pipe({ name: "daySidebar" })
export class daySidebarPipe implements PipeTransform {
  transform(date: number | string, today: string): string {
    const isToday = moment().isSame(date, "day");
    if (isToday) return today;

    const isTomorrow = moment().add(1, "days").isSame(date, "day");
    if (isTomorrow) return "Demain";

    return moment(date).format("DD/MM/YYYY");
  }
}

@Pipe({ name: "total" })
export class totalPipe implements PipeTransform {
  transform(total: number): string {
    return ("" + total.toFixed(2)).replace(/\./g, ",") + " €";
  }
}

@Pipe({ name: "plural" })
export class pluralPipe implements PipeTransform {
  transform(number: number): string {
    return number > 1 ? "s" : "";
  }
}

@Pipe({ name: "stringToNumber" })
export class stringToNumberPipe implements PipeTransform {
  transform(number: string): number {
    return parseFloat(number);
  }
}

@Pipe({ name: "rest" })
export class restPipe implements PipeTransform {
  transform(number: string): string {
    return moment(number).fromNow();
  }
}

@Pipe({ name: "preparationHour", pure: false })
export class preparationHourPipe implements PipeTransform {
  constructor(private commandeService: CommandesService) {}
  transform(order: Commande): Observable<string> {
    return this.commandeService.getDeliveryHour(order);
  }
}

@Pipe({ name: "preparationHourImpure", pure: false })
export class preparationHourPipeImpure implements PipeTransform {
  constructor(private commandeService: CommandesService) {}
  transform(order: Commande): Observable<string> {
    return this.commandeService.getDeliveryHour(order);
  }
}

@Pipe({ name: "roundUpPrice", pure: false })
export class RoundUpPrice implements PipeTransform {
  transform(value: string): string {
    return parseFloat(value).toFixed(2);
  }
}
