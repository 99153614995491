import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Headers':'Content-Type, Authorization',
    'Accept': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  readonly urlEnv = environment.apiURL;
  readonly apiKey = environment.apiPrestashopKey;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Formalise les requête API
   * @param method  get | put | post | delete
   * @param url url de l'api
   * @param obj payload
   * @param httpOptionsSpecific header spécifique pour la requête
   */
  apiRequest( method:string, url:string, obj?: any, httpOptionsSpecific = {}, parameters?: any): Observable<any> {
    let params = ``
    parameters ? params += parameters : null;
    // url =  this.urlEnv + url + params;
    url =  this.urlEnv + url + params;
    httpOptionsSpecific = {...httpOptions, ...httpOptionsSpecific}
    switch(method){
      case 'get':
        return this.http.get(url, httpOptionsSpecific).pipe(catchError(this.handleError))
      case 'put':
        return this.http.put(url, obj, httpOptionsSpecific).pipe(catchError(this.handleError))
      case 'post':
        return this.http.post(url, obj, httpOptionsSpecific).pipe(catchError(this.handleError))
      case 'delete':
        return this.http.delete(url, httpOptionsSpecific).pipe(catchError(this.handleError))
    }
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error)
    let errorMsg : string = "Une erreur est survenue. Veuillez réessayer ultérieurement";
    if(error.status == 500){
      errorMsg = "Une erreur est survenue. Veuillez réessayer ultérieurement";
    }
    else if(error.error?.message){
      errorMsg = error.error.message;
    }
    return throwError(errorMsg);
  };

}
