<div class="selectProfil__container">
    <img class="logo" [src]="logoSrc" />
    <div>
        <div class="selectProfil__top">
            <span>{{shopName$ | async}}</span>
        </div>
        <div class="profile-list">
            <div class="profile-list-container">
                <div *ngFor="let profile of profiles; let index = index;" class="profil__item"
                    [class.active]="choiceIndex == index" (click)="setChoiceIndex(index)">
                    <div class="profil__image" [style.backgroundImage]="'url('+(profile.profil_picture)+')'"></div>
                    <span class="profil__name">{{profile.name}}</span>
                    <img src="/assets/images/profilCheck.png" class="profil__check" />
                </div>
            </div>
        </div>
    </div>
    <button mat-flat-button color="primary" (click)="start()" class="button__start">
        Commencer
    </button>
    <div class="help">
        <span>Help !</span>
        <span class="fs19">{{shopCallCenter$ | async}}</span> 
    </div>
    <button class="selectProfil__disconnect" (click)="_logout()">Se déconnecter</button>
</div>