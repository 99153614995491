import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';


@Injectable({
  providedIn: 'root',
})



export class ReportingService {
  order = null;
  storeId = this.authService.getStoreId();
  constructor(
    private httpService: HttpService,
    private authService: AuthService,
  ) {

  }

  getDashboard(date_begin: number, date_end: number): Observable<any> {
    return this.httpService.apiRequest('get', `dashboard?date_begin=${date_begin}&date_end=${date_end}&id_store=${this.storeId}`, {}, {})
  }

  getTopFive(): Observable<any> {
    return this.httpService.apiRequest('get', `dashboard?action=topfive&id_store=${this.storeId}`, {}, {})
  }
}