import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { MessageService } from 'src/app/utils/message/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
})
export class AutoLoginComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const isLoggedIn: boolean = this.authService.isLoggedIn;
    this.route.paramMap.subscribe(
      (params) => {
        if(params.get('token')){
          if(isLoggedIn){
            this.authService.logout(true, params.get('token'));
          }
          this.authService.autologin(params.get('token')).subscribe(
            this.redirect.bind(this),
            this.showLoginError.bind(this),
          );
        }else{
          this.router.navigate(['/'])
        }
      }
    )
  }

  redirect(redirectUrl): void {
    this.router.navigateByUrl(redirectUrl)
  }

  showLoginError(error): void {
    this.messageService.openSnackBar(error, 'error')
  }
  getErrorMessage(control: UntypedFormControl): string {
    return this.messageService.getErrorMessage(control);
  }

}
