import { Injectable } from "@angular/core";
import { HttpInterceptor, HTTP_INTERCEPTORS, HttpRequest, HttpHandler, HttpEvent, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../loader/loader.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authService: AuthService,
        private loaderService: LoaderService
    ) { }

    // Ajoute un Bearer token à une requête donnée
    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if(!req.headers.has('authorization')){
            return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
        } else {
            return req
        }
    }

    // Intercepte les requêtes HTTP
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //On passe le loader à true
        this.loaderService.show();
        return next.handle(this.addToken(req, this.authService.getAccessToken())).pipe(
            catchError(error => {
                if (error.status === 401) {
                    return this.handle401Error(req, next);
                }
                return throwError(error);
            }),
            //On passe le loader à false après 1 seconde pour ne pas avoir d'effet bizarre
            finalize(() => {
                setTimeout(() => {
                    this.loaderService.hide()
                }, 1000);
            })
        )
    }

    // Gère l'erreur 401 (par exemple si le Token de connexion n'est plus valide)
    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        // On gère le refreshToken qui tombe en 401
        if(req.url === this.authService.getNewRefreshtokenUrl()){
            this.authService.logout(true)
        }
        if (this.isRefreshingToken) {
            // Si déjà une demande, on attend le nouvel access token pour relancer la requête
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                })
            )
        }
        else {
            this.isRefreshingToken = true;
            // reinit l'observable
            this.tokenSubject.next(null);
            // demande de nouveau token
            return;
        }
    }

}


export const httpInterceptorTokenProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
];
