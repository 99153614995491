<div class="login-container reset-password-container">
  <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
    <img class="logo" [src]="logoSrc" />
    <div class="form_container">
      <mat-form-field>
        <mat-label>Mot de passe</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" [formControl]="resetPassword">
        <mat-error>{{getErrorMessage(resetPassword)}}</mat-error>
        <button mat-icon-button matSuffix (click)="hide = !hide" type="button">
          <mat-icon matSuffix fontSet="fontello" [fontIcon]="hide ? 'eye-off' : 'eye'"></mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Confirmation du nouveau mot de passe</mat-label>
        <input matInput [type]="hideConfirm ? 'password' : 'text'" [formControl]="resetPasswordConfirm" [errorStateMatcher]="confirmErrorMatcher">
        <mat-error *ngIf="resetPasswordForm.controls.passwords.errors?.areEqual">Les mots de passes ne sont pas identiques</mat-error>
        <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" type="button">
          <mat-icon matSuffix fontSet="fontello" [fontIcon]="hideConfirm ? 'eye-off' : 'eye'"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <button mat-flat-button color="primary" type="submit" [disabled]="resetPasswordForm.invalid" class="button__connect">
      Enregistrer mon nouveau mot de passe
    </button>
  </form>
</div>