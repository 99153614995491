import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MessageService } from 'src/app/utils/message/message.service';
import { emailValidator } from 'src/app/utils/validator/validators.service';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// @ts-ignore
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  resetLogin = new UntypedFormControl(
    '',
    [
      Validators.required,
    ]
  );
  public logoSrc = environment.theme === 'jdf' ? 'assets/images/logo.svg' : 'assets/images/logo-oya.png';

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      resetLogin: this.resetLogin
    })
  }
  onSubmit(): void {    
    this.authService.requestResetPassword(this.resetLogin.value).subscribe(
      success => { 
        this.messageService.openSnackBar('Un email vous a été envoyé pour réinitialiser votre mot de passe', 'success')
      },
      error => { 
        this.messageService.openSnackBar(error, 'error')
       }
    )
  }
  getErrorMessage(control: UntypedFormControl): string {
    return this.messageService.getErrorMessage(control);
  }
}
