import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

const passwordPattern: RegExp = /^(?=.*[0-9])(?=.*[a-zA-Z])(\S+)$/;
const customEmailPattern: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const ipPattern: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const passwordMinLength: number = 8;
export const passwordMaxLength: number = 30;


export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return customEmailPattern.test(control.value) ? null : {'customEmail': true};
  };
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return passwordPattern.test(control.value) ? null : {'password': true};
  };
}

export function areEqual(group: UntypedFormGroup) {

  let valid = false;
  let val: string = "";

  for (let name in group.controls) {
    if (val == "")
      val = group.controls[name].value;
    else if (val === group.controls[name].value)
      valid = true;
  }

  if (valid) {
    return null;
  }

  return {
    areEqual: true
  };
}

export function ipValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return ipPattern.test(control.value) ? null : {'ip': true};
  };
}