import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { MessageService } from 'src/app/utils/message/message.service';
import { Router } from '@angular/router';
import { passwordValidator, passwordMinLength } from 'src/app/utils/validator/validators.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  login = new UntypedFormControl('', [Validators.required]);
  password = new UntypedFormControl('', [
    Validators.required, 
    Validators.minLength(passwordMinLength), 
    passwordValidator()
  ]);
  hide: boolean = true;
  public logoSrc = environment.theme === 'jdf' ? 'assets/images/logo.svg' : 'assets/images/logo-oya.png';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      login: this.login,
      password: this.password,
    })
  }

  redirect(redirectUrl): void {
    this.router.navigateByUrl(redirectUrl)
  }

  showLoginError(error): void {
    this.messageService.openSnackBar(error, 'error')
  }

  onSubmit(): void {
    const {login, password} = this.loginForm.value
    this.authService.login(login, password).subscribe(
      this.redirect.bind(this),
      this.showLoginError.bind(this),
    );
  }
  getErrorMessage(control: UntypedFormControl): string {
    return this.messageService.getErrorMessage(control);
  }

}
