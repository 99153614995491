import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, first, switchMap, map, take, share } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { AuthService } from '../auth/auth.service';
import { Store, select } from '@ngrx/store';
import { setInfosCommande, addCommandsToModal, addInPending, setPending, deleteInModal, deleteInPending, addInAccepted, setAccepted, addInArchived, setArchived, deleteInAccepted, setNewLeader, setPendingCommandToModal, refreshPendingCommandInModal } from 'src/app/reducers/commande/commande.action';
import { Commande, getCommandeInterface, dataPagination, CommandeMultiboutique } from 'src/app/models/commande';
import { makeAnExemple2 } from 'src/app/reducers/commande/exemple';
import * as moment from 'moment';
import { selectCommandeDeliveryTime, selectCommandePreparationTime, selectCommandeSound } from 'src/app/reducers/commande/commande.selector';
import { Params } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialog } from '@angular/material/dialog';
import {HttpHeaders} from "@angular/common/http";


const STORAGE_KEY = "floranova_app_profil"

const getCommandeDefaultOptions: getCommandeInterface = {
  status: ["accepted", "pending", "ready", "refused", "canceled", "new", "shipped"],
  // order: "created_at",
  // direction: "DESC",
  // page: false,
  // item_per_page: false,
  // id: false,
  // from: false,
  // to: false
}

@Injectable({
  providedIn: 'root',
})
export class CommandesService {
  order = null;
  readonly item_per_page = 2

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private dialog: MatDialog,
    private store: Store,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  getCommandes(status: string[], urlVersion: string = "V1"): Observable<any> {
    // const data = { ...getCommandeDefaultOptions, ...options }
    return this.httpService.apiRequest(
      'post',
      'orders',
      { status,
        storeId: this.authService.getStoreId()
      },
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.getAccessToken()}) }
    ).pipe(
      take(1),
      share(),
      tap(
        success => {
          const { delivery_time, nb_orders, nb_received_orders_last_week, nb_received_orders_today, preparation_time, total_orders, chronopostConfig} = success;
          const info = { delivery_time, nb_orders, nb_received_orders_last_week, nb_received_orders_today, preparation_time, total_orders, chronopostConfig }
          this.store.dispatch(setInfosCommande({info}))
        }
      )
    )
  }

  changeStatus(idOrder: string, status: string):Observable<any> {
    return this.httpService.apiRequest(
        'post',
        'orders/status',
        {idOrder, status},
        {}
    )
  }

  getPdfDelivery(refOrder: string):Observable<any> {
    return this.httpService.apiRequest(
        'get',
        `pdf/delivery/${refOrder}`,
        {},
        {responseType: 'blob' as 'json'}
    )
  }

  getInvoice(refOrder: string):Observable<any> {
    return this.httpService.apiRequest(
        'get',
        `pdf/invoice/${refOrder}`,
        {},
        {responseType: 'blob' as 'json'}
    )
  }


  /**
   * Met à jour le store avec les commandes 'new'
   */
  refreshNew(): void {
    const paramcommandes: getCommandeInterface = { status: ['new'] }
    this.getCommandes(['new']).pipe(
      // this.fakeCommande(['new']).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data.orders
        // Notification sonore
        // Si nouvelle(s) commande(s) en 'new'
        // Si notif sonore activé
        if (commandes?.length) {
          this.store.pipe(
            take(1),
            select(selectCommandeSound),
          ).subscribe((active => {
            if (active) {
              new Audio('/assets/sounds/notif.mp3').play();
            }
          }))
        }
        // On place les nouvelles commandes dans la modale de notifications  de commande
        this.store.dispatch(addCommandsToModal({ commandes }))
        // On les place tout de suite en status pending
        // Appel API + changement dans le store
        commandes?.forEach(commande => {
          this.changeStatus(commande.order_id, "pending").subscribe(
            _ => { this.store.dispatch(addInPending({ commandes: [commande] })) }
          )
        });
      }
    )
  }

  /**
   * Met à jour le store avec les commandes 'pending'
   */
  refreshPending(): void {
    const paramcommandes: getCommandeInterface = { status: ['pending'] }
    this.getCommandes(['pending'] ).pipe(
      // this.fakeCommande(['pending']).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data.orders
        this.store.dispatch(setPending({ commandes }))
      }
    )
  }

  refreshModalsPending(id: string): void {
    const paramcommandes: getCommandeInterface = { status: ['pending'] }
    this.getCommandes(['pending']).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data.orders
        this.store.dispatch(refreshPendingCommandInModal({ commandes, id }))
      }
    )
  }


  /**
   * Met à jour le store avec les commandes 'accepted'
   */
  refreshAccepted(): void {
    const paramcommandes: getCommandeInterface = { status: ['accepted','pending_cnc','pending_delivery'] }
    this.getCommandes(['accepted','pending_cnc','pending_delivery']).pipe(
      first(),
    ).subscribe(
      data => {
        const commandes: Commande[] = data?.orders?.filter(order => {
          return order.status !== 'canceled';
        })
        this.store.dispatch(setAccepted({ commandes }))
      }
    )
  }

  /**
   * Met à jour le store avec les commandes 'archived'
   */
  refreshArchived(queryParams: Params): Observable<dataPagination> {
    const paramcommandes: getCommandeInterface = {
      status: ['ready', 'canceled', 'refused', 'shipped'],
      order: "delivery_date",
      item_per_page: this.item_per_page,
      id: queryParams.id || false,
      from: queryParams.date ? this.formatDateMySQL(queryParams.date, true) : false,
      to: queryParams.date ? this.formatDateMySQL(queryParams.date, false) : false,
      page: queryParams.page || 1
    }
    return this.getCommandes(['ready', 'canceled', 'refused', 'shipped']).pipe(
      first(),
      map(data => {
        let commandes: Commande[] = data.orders
        this.store.dispatch(setArchived({ commandes }))
        return { nb_orders: data.nb_orders, current_page: data.current_page, max_page: data.max_page }
      })
    )
  }

  refreshArchivedV2(queryParams: Params): Observable<dataPagination> {
    const paramcommandes: getCommandeInterface = {
      status: ['ready', 'canceled', 'refused', 'shipped'],
      order: "delivery_date",
      item_per_page: this.item_per_page,
      id: queryParams.id || false,
      from: queryParams.date ? this.formatDateMySQL(queryParams.date, true) : false,
      to: queryParams.date ? this.formatDateMySQL(queryParams.date, false) : false,
      page: queryParams.page || 1
    }
    return this.getCommandes(['ready', 'canceled', 'refused', 'shipped'], "V2").pipe(
      first(),
      map(data => {
        let commandes: Commande[] = data.orders
        if(paramcommandes.id){
          commandes = commandes.filter(function(value, index, arr){return Number(value.order_id) == paramcommandes.id;})
        }
        if(paramcommandes.from || paramcommandes.to){
          let $from = new Date(String(paramcommandes.from));
          let $to = new Date(String(paramcommandes.to));
          commandes = commandes.filter(function(item: any){
              let $delivery = new Date(item.delivery_date);
              return $delivery.getTime() >= $from.getTime() && $delivery.getTime() <= $to.getTime();
          }
          );
        }
        this.store.dispatch(setArchived({ commandes }))
        return { nb_orders: data.nb_orders, current_page: data.current_page, max_page: data.max_page }
      })
    )
  }

  /**
   * On accepte une commande
   */
  acceptCommande(commande: Commande): void {
    this.changeStatus(commande.order_id, 'accepted').subscribe(
      _ => {
        const id = commande.order_id

        let order = commande;

        //On rafraichit la liste pour avoir les bons status des sous commandes
        this.refreshAccepted()

        // on supprime de 'ShowInModal'
        this.store.dispatch(deleteInModal({ id }))
        // on supprime de 'pending'
        this.store.dispatch(deleteInPending({ id }))
        // on ajoute dans 'accepted'
        this.store.dispatch(addInAccepted({ commandes: [order] }))

      }
    )
  }

  /**
   * On refuse une commande
   */
  refuseCommande(commande: Commande): void {
    this.changeStatus(commande.order_id, 'refused').subscribe(
      _ => {
        const id = commande.order_id

        let order = commande;

        // on supprime de 'ShowInModal'
        this.store.dispatch(deleteInModal({ id }))
        // on supprime de 'pending'
        this.store.dispatch(deleteInPending({ id }))
        // on ajoute dans 'archived'
        this.store.dispatch(addInArchived({ commandes: [order] }))
      }
    )
  }

  /**
   * On annule la commande
   */
  cancelOrder(commande: Commande): void {
    this.changeStatus(commande.order_id, 'canceled').subscribe(
      _ => {
        const id = commande.order_id
        // on supprime de 'accepted'
        this.store.dispatch(deleteInAccepted({ id }))
        // on ajoute dans 'archived'
        this.store.dispatch(addInArchived({ commandes: [commande] }))
      }
    )
  }

  /**
   * Met à jour le status app_status pour pallier à l'affichage de la modale d'annulation de commande
   */
  changeAppStatusShop(subOrderId: string, status: string): Observable<any> {
    return this.httpService.apiRequest('post',
      'trader/order/appstatus',
      { subOrderId, status },
      {}
    )
  }

  pendingOrderCnc(commande: Commande): void {
    this.changeStatus(commande.order_id, 'pending_cnc').subscribe(_ => {
      this.refreshAccepted()
    })
  }

  pendingOrderDelivery(commande: Commande): void {
    this.changeStatus(commande.order_id, 'pending_delivery').subscribe(_ => {
      this.refreshAccepted()
    })
  }

  /**
   * On termine la commande
   */
  readyOrder(commande: Commande): void {
    this.changeStatus(commande.order_id, 'ready').subscribe(
      _ => {
        const id = commande.order_id
        // on supprime de 'accepted'
        this.store.dispatch(deleteInAccepted({ id }))
        // on ajoute dans 'archived'
        this.store.dispatch(addInArchived({ commandes: [commande] }))
      }
    )
  }

  readyOrderColissimoMulti(commande: Commande): void {
    this.changeStatus(commande.order_id, 'pending_ready_colissimo').subscribe(
      _ => {
        //Si c'est une commande multiboutique, on change le status dans le sub_orders_related pour ne pas attendre l'api
        if (commande.sub_orders_related.length > 0) {
          //On passe la boutique en leader de la commande
          this.store.dispatch(setNewLeader({ commande }))
        }
      }
    )
  }

  //Construction  du payload pour l'api de changement de quantité.
  setPayloadModificationQuantity(order: Commande) {
    let payload = { data: [] }
    order.items.categories.forEach(el => {
      el.choices.forEach(element => {
        payload = {
          data: [
            ...payload.data,
            {
              qty: element.qty,
              item_id: element.item_id
            }
          ]
        }
      })
    })
    order.items.menus.forEach(menu => {
      payload = {
        data: [
          ...payload.data,
          {
            qty: menu.qty,
            item_id: menu.item_id
          }
        ]
      }
    })
    return payload
  }

  editQuantityOrder(subOrderId: string, items) {
    return this.httpService.apiRequest('post', `shopkeeper/update/suborder/${subOrderId}`, items)
  }

  /**
   * On expédie la commande
   */
  shippedOrder(commande: Commande): void {
    this.changeStatus(commande.order_id, 'ready').subscribe(
      _ => {
        this.changeStatus(commande.order_id, 'shipped').subscribe(
          _ => {
            const id = commande.order_id
            // on supprime de 'accepted'
            this.store.dispatch(deleteInAccepted({ id }))
            // on ajoute dans 'archived'
            this.store.dispatch(addInArchived({ commandes: [commande] }))
          }
        )
      }
    )
  }

  /**
   * Récupération de l'heure selon le type de livraison de la commande
   * @param commande
   */
  getDeliveryHour(commande: Commande): Observable<any> {
    if (!commande) {
      return of(null)
    }
    else {
      return this.store.select(selectCommandeDeliveryTime).pipe(
        map(deliveryTime => {
          return commande.delivery_type == "click_and_collect"
            ? moment(commande.delivery_date)
            : moment(commande.delivery_date).subtract(deliveryTime, "minutes")
        })
      )
    }
  }

  /**
   * Calcul du timer
   * @param commande
   * @return Pourcentage de temps restant
   */
  timer(commande: Commande): Observable<number> {
    return this.store.select(selectCommandePreparationTime).pipe(
      switchMap(preparationTime => {
        return this.getDeliveryHour(commande).pipe(
          map(deliveryTime => {
            let dateNow = moment().unix()
            return this.interpolateTimer(dateNow, moment(deliveryTime).unix(), moment(parseInt(preparationTime) * 60 * 1000).unix())
          })
        )
      })
    )
  }

  /**
   *
   * @param dateNow
   * @param deliveryTime
   * @param preparationTime
   */
  interpolateTimer(dateNow: number, deliveryTime: number, preparationTime: number) {
    let x = dateNow,
      minX = deliveryTime - preparationTime,
      maxX = deliveryTime,
      minY = 0,
      maxY = 100
    x = Math.max(minX, Math.min(maxX, x))
    return (x - minX) * (maxY - minY) / (maxX - minX) + minY;
  }

  /**
   * Formate les dates au format DATETIME MySQL pour envoyer à l'API
   */
  formatDateMySQL(date: string, start: boolean): string {
    return (
      moment(date)
        .hour(start ? 0 : 23)
        .minute(start ? 0 : 59)
        .second(start ? 0 : 59)
        .format("YYYY-MM-DD HH:mm:ss"))
  }


  /**
   * FakeCommande
   */
  fakeCommande(availableStatus): Observable<any> {
    return this.getCommandes(['accepted']).pipe(
      map(
        _ => {
          const nbCommandes = 8;
          const days = 3;
          const availableHours = ['16:00', '18:10', '20:15']
          // const availableMethod = ['home_delivery', 'click_and_collect', 'chronopost']
          const availableMethod = ['chronopost']
          const availableMultiboutique = [
            [
              { 'id_shop': '4', 'name': 'TRAN', 'is_primary': '1', 'status': 'accepted', 'app_status': 'accepted' },
              { 'id_shop': '42', 'name': 'toto2', 'is_primary': '0', 'status': 'refused', 'app_status': 'pending' },
              { 'id_shop': '44', 'name': 'toto3', 'is_primary': '0', 'status': 'pending', 'app_status': 'pending' },
              { 'id_shop': '45', 'name': 'toto4', 'is_primary': '0', 'status': 'pending', 'app_status': 'pending' },
            ],
          ]

          let orders = []
          for (let index = 0; index < nbCommandes; index++) {
            const date = moment(availableHours[this.randomNumber(availableHours.length)], 'HH:mm').add(this.randomNumber(days) - 0, 'days').format('YYYY-MM-DD HH:mm:ss')

            orders.push(
              makeAnExemple2(
                (10000 + index).toString(),
                // (10000 + this.randomNumber(9999)).toString(),
                date,
                availableStatus[this.randomNumber(availableStatus.length)],
                availableMethod[this.randomNumber(availableMethod.length)],
                availableMultiboutique[this.randomNumber(availableMultiboutique.length)]
              )
            )
          }
          return { orders }
          return ({
            orders: [
              makeAnExemple2("1001", "2020-05-15 11:05:00", "ready", "home_delivery", []),
            ]
          })
        }
      )
    )
  }
  randomNumber(nb: number): number {
    return Math.floor(Math.random() * nb)
  }
}
