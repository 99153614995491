// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  port: "8008",
  apiURL: "https://staging.lejardindesfleurs.com/rest/",
  back: "https://staging.lejardindesfleurs.com/",
  apiPrestashopKey: "WEC41HWN7RU51T4NWDE5GYFKKAWE3R1F",
  code: 'UA-150838403-4',
  theme: 'jdf'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
