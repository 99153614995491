import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, map, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { HttpService } from '../http/http.service';
import { Store, select } from '@ngrx/store';
import { resetAllProfil } from 'src/app/reducers/profil/profil.action';
import { HttpHeaders } from '@angular/common/http';
import { selectProfilShopId } from 'src/app/reducers/profil/profil.selector';
import {Commande} from "../../models/commande";

const STORAGE_KEY = "floranova_app_tokens"

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn: boolean = false
  redirectUrl: string = "/";

  constructor(
    private router: Router,
    private httpService: HttpService,
    private store: Store,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.isLoggedIn = (this.storage.get(STORAGE_KEY)?.accessToken !== undefined);
    //this.isLoggedIn = true;
  }

  getAccessToken(): string {
    return this.storage.get(STORAGE_KEY)?.accessToken
  }

  getStoreId(): string {
    return this.storage.get(STORAGE_KEY)?.storeId
  }

  getStoreName(): string {
    return this.storage.get(STORAGE_KEY)?.storeName
  }

  setTokens(tokens: { accessToken: string; storeId: number, storeName: string }):void {
    const {accessToken, storeId, storeName} = tokens
    this.storage.set(STORAGE_KEY, { accessToken, storeId, storeName })
  }

  login(username: string, password: string): Observable<string> {
    let obj = { username, password }
    return this.httpService.apiRequest('post', 'login', obj).pipe(
      tap(data => {
        // On récupère ici le success de l'observable pour mettre à jour les données lié à l'authentification
        // La gestion de la redirection ou de l'affichage de message d'erreur ne se fait pas ici
        this.isLoggedIn = true;
        const accessToken: string = data.accessToken
        const storeId: number = data.storeId
        const storeName: string = data.storeName
        this.setTokens({ accessToken, storeId, storeName })
      }),
      map(() => {
        // On retourne l'url de redirection au lieu de l'access token
        return this.redirectUrl;
      })
    )
  }

  autologin(token: string): Observable<string> {
    let obj = { token }
    return this.httpService.apiRequest('post', 'login/autoLogin', obj).pipe(
      tap(data => {
        // On récupère ici le success de l'observable pour mettre à jour les données lié à l'authentification
        // La gestion de la redirection ou de l'affichage de message d'erreur ne se fait pas ici
        this.isLoggedIn = true;
        const accessToken: string = data.accessToken
        const storeId: number = data.storeId
        const storeName: string = data.storeName
        this.setTokens({ accessToken, storeId, storeName })
      }),
      map(() => {
        // On retourne l'url de redirection au lieu de l'access token
        return this.redirectUrl;
      })
    )
  }

  getNewRefreshtokenUrl(): string{
    return `${this.httpService.urlEnv}shopkeeper/user/refreshToken`
  }

  logout(force = false, tokenLogin?: string): void {
    this.isLoggedIn = false;
    // Suppression du local store
    this.storage.remove(STORAGE_KEY)
    this.storage.remove("floranova_app_profil")
    this.storage.remove("floranova_app_commande")
    if (tokenLogin) {
      window.location.href = '/login/' + tokenLogin;
      return;
    }
    if (force) {
      window.location.href = '/auth'
    } else {
      this.router.navigate(['/auth'])
    }

  }

  requestResetPassword(username: string): Observable<any> {
    return this.httpService.apiRequest(
      'post',
      'shopkeeper/user/requestResetPassword',
      {username}
    );
  }

  resetPassword(user_id: string, token: string, password: string): Observable<any> {
    return this.httpService.apiRequest(
      'post',
      'shopkeeper/user/resetPassword',
      {user_id, token, password});
  }

  getMessageMaintenance(): Observable<any> {
    return this.httpService.apiRequest('get', 'shopkeeper/maintenance/message')
  }

  hideBandeauMaintenance(){
    return this.httpService.apiRequest('post', 'shopkeeper/maintenance/message/view')
  }
}
