import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SnackBarComponent } from 'src/app/_components/snackbar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

const _defaultErrorMessages = {
  required: () => `Ce champ est requis`,
  email: () => `Ce n'est pas un format d'email valide`,
  customEmail: () => `Ce n'est pas un format d'email valide`,
  password: () => `Le mot de passe doit contenir au moins une lettre et un chiffre`,
  ip: () => `Cette adresse ip n'est pas valide`,
  minlength: ({requiredLength, actualLength}) => `Ce champ doit contenir au minimum ${requiredLength} caractères`,
  maxlength: ({requiredLength, actualLength}) => `Ce champ doit contenir au maximum ${requiredLength} caractères`,
  default: () => `Ce champ contient au moins une erreur`
}


@Injectable({
  providedIn: 'root',
})
export class MessageService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  getErrorMessage(control: UntypedFormControl, errorMessages?: any): string {
    errorMessages = {..._defaultErrorMessages, ...errorMessages}
    let erreur = errorMessages.default()

    for (const type in errorMessages) {
      if(type !== "default" && control.hasError(type)){
        erreur = errorMessages[type](control.getError(type))
        break;
      }
    }
    return erreur;
  }

  /**
   * Ouverture de la snackbar
   * @param message 
   * @param classe 
   */
  openSnackBar(message: string, classe: string, duration = 2000, action?: string, callback?: any){
    const snackBarRef = this._snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message,
        action
      },
      panelClass: classe,
      duration: duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'right'
    });
    if(action){
      const snackBarSubscription = snackBarRef.onAction().subscribe(() => {
        callback()
        snackBarRef.dismiss()
      });
      snackBarRef.afterDismissed().subscribe(() => {
        snackBarSubscription.unsubscribe()
      });
    }
  }
}
